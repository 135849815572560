<template>
  <!-- eslint-disable -->
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light text-center">
              <h3>Forgot Password</h3>
            </div>
          </template>
          <!-- <div class="subtitle-1 font-weight-light">
              <h3>  Complete your profile</h3>
          </div>-->
          <v-form>
            <v-container class="py-0">
                <span> <h2>Please Enter Your Email to send the code</h2> </span>
              <!-- <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    class="purple-input"
                    label="User Name"
                  />
                </v-col>
              </v-row>-->
              <v-row>
                  
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="email"
                    label="Email Address"
                    class="purple-input"
                    type="text"
                  />
                </v-col>
                
              </v-row>
              <v-row>
                <!-- <v-col cols="12" md="6">
                  <v-text-field
                    v-model="password"
                    label="Password"
                    class="purple-input"
                    type="password"
                  />
                </v-col> -->

                <v-col cols="12" class="text-center">
                  <v-btn color="primary" class="mr-0" @click="submitFn">Submit</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
export default {
  name: "LogIn",
  data() {
    return {
      email: null,
      password: null
    };
  },
  methods: {
    submitFn() {
        this.$router.push("reset-pwd")
    }
  }
};
</script>
